import React, { useState, useEffect, useCallback } from 'react';
import './BossTimer.css';

const bossSchedule = {
  "00:00": { "Mon": ["Kutum"], "Tue": ["Karanda"], "Wed": ["Kutum"], "Thu": ["Nouver"], "Fri": ["Offin"], "Sat": ["Kzarka"], "Sun": ["Karanda"] },
  "03:00": { "Mon": ["Kzarka"], "Tue": ["Kzarka"], "Wed": ["Kzarka"], "Thu": ["Nouver"], "Fri": ["Karanda"], "Sat": ["Nouver"], "Sun": ["Kutum"] },
  "07:00": { "Mon": ["Kzarka"], "Tue": ["Nouver"], "Wed": ["Karanda"], "Thu": ["Kutum"], "Fri": ["Kutum"], "Sat": ["Kutum"], "Sun": ["Nouver"] },
  "10:00": { "Mon": ["Offin"], "Tue": ["Kutum"], "Wed": ["Nouver"], "Thu": ["Offin"], "Fri": ["Karanda"], "Sat": ["Nouver"], "Sun": ["Kzarka"] },
  "12:00": { "Mon": ["Garmoth"], "Tue": ["Garmoth"], "Wed": ["Garmoth"], "Thu": ["Garmoth"], "Fri": ["Garmoth"], "Sat": ["Garmoth"], "Sun": ["Garmoth"] },
  "14:00": { "Mon": ["Kutum"], "Tue": ["Nouver"], "Wed": ["Kutum"], "Thu": ["Nouver"], "Fri": ["Nouver"], "Sat": ["Kzarka"], "Sun": ["Vell"] },
  "17:00": { "Mon": ["Nouver"], "Tue": ["Karanda"], "Wed": ["Vell"], "Thu": ["Kzarka"], "Fri": ["Kzarka"], "Sat": ["Karanda"], "Sun": ["Garmoth"] },
  "20:15": { "Mon": ["Kzarka"], "Tue": ["Kutum"], "Wed": ["Kzarka"], "Thu": ["Kutum"], "Fri": ["Kutum"], "Sat": ["Kutum"], "Sun": ["Kzarka"] },
  "21:15": { "Mon": ["Garmoth"], "Tue": ["Garmoth"], "Wed": ["Garmoth"], "Thu": ["Garmoth"], "Fri": ["Garmoth"], "Sat": ["Garmoth"], "Sun": ["Garmoth"] },
  "22:15": { "Mon": ["Karanda"], "Tue": ["Kzarka"], "Wed": ["Nouver"], "Thu": ["Karanda"], "Fri": ["Karanda"], "Sat": ["Nouver"], "Sun": ["Karanda"] }
};


function BossTimer() {
  const [nextBoss, setNextBoss] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState("");
  const [highlightedTime, setHighlightedTime] = useState("");
  const [highlightedDay, setHighlightedDay] = useState("");
  const [play10MinSound, setPlay10MinSound] = useState(true);
  const [play5MinSound, setPlay5MinSound] = useState(true);
  const [play1MinSound, setPlay1MinSound] = useState(true);
  const [playedNotifications, setPlayedNotifications] = useState({ 10: false, 5: false, 1: false });
  const [volume, setVolume] = useState(0.5);

  // Load settings from localStorage on component mount
  useEffect(() => {
    const storedPlay10MinSound = JSON.parse(localStorage.getItem('play10MinSound'));
    const storedPlay5MinSound = JSON.parse(localStorage.getItem('play5MinSound'));
    const storedPlay1MinSound = JSON.parse(localStorage.getItem('play1MinSound'));
    const storedVolume = JSON.parse(localStorage.getItem('volume'));

    setPlay10MinSound(storedPlay10MinSound !== null ? storedPlay10MinSound : true);
    setPlay5MinSound(storedPlay5MinSound !== null ? storedPlay5MinSound : true);
    setPlay1MinSound(storedPlay1MinSound !== null ? storedPlay1MinSound : true);
    setVolume(storedVolume !== null ? storedVolume : 0.5);
  }, []);

  // Save settings to localStorage whenever they change
  const updatePlay10MinSound = (value) => {
    setPlay10MinSound(value);
    localStorage.setItem('play10MinSound', JSON.stringify(value));
  };

  const updatePlay5MinSound = (value) => {
    setPlay5MinSound(value);
    localStorage.setItem('play5MinSound', JSON.stringify(value));
  };

  const updatePlay1MinSound = (value) => {
    setPlay1MinSound(value);
    localStorage.setItem('play1MinSound', JSON.stringify(value));
  };

  const updateVolume = (value) => {
    setVolume(value);
    localStorage.setItem('volume', JSON.stringify(value));
  };

  const playSound = useCallback((boss, minutes) => {
    const audioFile = `/sounds/${boss.toLowerCase()}${minutes}.mp3`;
    const audio = new Audio(audioFile);
    audio.volume = volume;
    audio.play();
  }, [volume]);

  const testSound = useCallback(() => {
    const audioFile = '/sounds/kzarka10.mp3';
    const audio = new Audio(audioFile);
    audio.volume = volume;
    audio.play();
  }, [volume]);

  const bossPriorities = {
    "Kzarka": 1,
    "Kutum": 2,
    "Karanda": 3,
    "Offin": 4,
    "Nouver": 5,
    "Vell": 6,
    "Garmoth": 7,
  };

  const handleSoundNotifications = useCallback((millisecondsRemaining) => {
    const tolerance = 2000; // Tolerance of ±2000ms (2 seconds)
    if (Math.abs(millisecondsRemaining - 600000) <= tolerance && play10MinSound && !playedNotifications[10]) {
      const highestPriorityBoss = nextBoss
        .sort((a, b) => bossPriorities[a] - bossPriorities[b])[0];
      if (highestPriorityBoss) {
        playSound(highestPriorityBoss, 10);
        setPlayedNotifications(prev => ({ ...prev, 10: true }));
      }
    }
    if (Math.abs(millisecondsRemaining - 300000) <= tolerance && play5MinSound && !playedNotifications[5]) {
      const highestPriorityBoss = nextBoss
        .sort((a, b) => bossPriorities[a] - bossPriorities[b])[0];
      if (highestPriorityBoss) {
        playSound(highestPriorityBoss, 5);
        setPlayedNotifications(prev => ({ ...prev, 5: true }));
      }
    }
    if (Math.abs(millisecondsRemaining - 60000) <= tolerance && play1MinSound && !playedNotifications[1]) {
      const highestPriorityBoss = nextBoss
        .sort((a, b) => bossPriorities[a] - bossPriorities[b])[0];
      if (highestPriorityBoss) {
        playSound(highestPriorityBoss, 1);
        setPlayedNotifications(prev => ({ ...prev, 1: true }));
      }
    }
  }, [nextBoss, play10MinSound, play5MinSound, play1MinSound, playedNotifications, playSound]);

useEffect(() => {
  const calculateNextBoss = () => {
    const now = new Date();
    const nowUTC = {
      year: now.getUTCFullYear(),
      month: now.getUTCMonth(),
      day: now.getUTCDate(),
      hours: now.getUTCHours(),
      minutes: now.getUTCMinutes(),
      seconds: now.getUTCSeconds()
    };

    let currentDay = now.toLocaleString('en-US', { weekday: 'short', timeZone: 'UTC' });
    const currentTime = now.toISOString().slice(11, 16); // Format HH:MM in UTC

    let nextBossTime = "";
    let nextBosses = [];

    // Find the next boss, considering the possibility of it being on the following days.
    for (let i = 0; i < 7; i++) { // Check the next 7 days
      const dayUTC = new Date(Date.UTC(nowUTC.year, nowUTC.month, nowUTC.day + i, 0, 0, 0));
      const checkDay = dayUTC.toLocaleString('en-US', { weekday: 'short', timeZone: 'UTC' });

      for (const time in bossSchedule) {
        if (i === 0 && time <= currentTime) continue; // Skip past times on the current day

        if (bossSchedule[time][checkDay].length > 0) {
          nextBossTime = time;
          nextBosses = bossSchedule[time][checkDay];
          currentDay = checkDay;
          break;
        }
      }
      if (nextBossTime) break; // Exit loop if a next boss is found
    }

    if (nextBossTime) {
      setNextBoss(nextBosses);
      setHighlightedTime(nextBossTime);
      setHighlightedDay(currentDay);

      const [hours, minutes] = nextBossTime.split(":").map(Number);
      const nextTimeUTC = new Date(Date.UTC(nowUTC.year, nowUTC.month, nowUTC.day, hours, minutes, 0));
      if (currentDay !== now.toLocaleString('en-US', { weekday: 'short', timeZone: 'UTC' })) {
        nextTimeUTC.setUTCDate(nextTimeUTC.getUTCDate() + 1); // Adjust for the next day if needed
      }

      const diffMs = nextTimeUTC - now;
      const diffHrs = String(Math.floor(diffMs / (1000 * 60 * 60))).padStart(2, '0');
      const diffMins = String(Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const diffSecs = String(Math.floor((diffMs % (1000 * 60)) / 1000)).padStart(2, '0');

      setTimeRemaining(`${diffHrs}:${diffMins}:${diffSecs}`);

      handleSoundNotifications(diffMs);
    } else {
      setNextBoss([]);
      setTimeRemaining("00:00:00");
      setHighlightedTime("");
      setHighlightedDay("");
      setPlayedNotifications({ 10: false, 5: false, 1: false });
    }
  };

  calculateNextBoss();
  const interval = setInterval(calculateNextBoss, 1000);

  return () => clearInterval(interval);
}, [handleSoundNotifications]);




  const bossImages = {
    "Kzarka": "/images/kzarka.png",
    "Kutum": "/images/kutum.png",
    "Karanda": "/images/karanda.png",
    "Offin": "/images/offin.png",
    "Nouver": "/images/nouver.png",
    "Vell": "/images/vell.png",
    "Garmoth": "/images/garmoth.png",
  };

  return (
    <div className="App">
      <div className="boss-timer">
        <div className="settings">
          <h2>Notification Settings</h2>
          <label>
            <input
              type="checkbox" 
              checked={play10MinSound} 
              onChange={(e) => updatePlay10MinSound(e.target.checked)} 
            />
            10 minutes sound
          </label>
          <label>
            <input 
              type="checkbox" 
              checked={play5MinSound} 
              onChange={(e) => updatePlay5MinSound(e.target.checked)} 
            />
            5 minutes sound
          </label>
          <label>
            <input 
              type="checkbox" 
              checked={play1MinSound} 
              onChange={(e) => updatePlay1MinSound(e.target.checked)} 
            />
            1 minute sound
          </label>
        </div>
        <div className="volume-control">
          <label>
            Volume:
            <input 
              type="range" 
              min="0" 
              max="1" 
              step="0.01" 
              value={volume} 
              onChange={(e) => updateVolume(parseFloat(e.target.value))}
            />
          </label>
          <button onClick={testSound}>Test</button>
        </div>
        <p>Next Boss: {nextBoss.join(" & ")}</p>
        {nextBoss.map(boss => bossImages[boss] && (
          <img 
            key={boss}
            src={bossImages[boss]} 
            alt={boss} 
            style={{ width: '100px', height: '100px' }} 
          />
        ))}
        <p class="timer">{timeRemaining}</p>
      </div>
      <div className="boss-schedule">
        <table>
          <thead>
            <tr>
              <th>UTC+0</th>
              <th>Mon</th>
              <th>Tue</th>
              <th>Wed</th>
              <th>Thu</th>
              <th>Fri</th>
              <th>Sat</th>
              <th>Sun</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(bossSchedule).map(([time, days]) => (
              <tr key={time}>
                <td>{time}</td>
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(day => {
                  const isHighlighted = time === highlightedTime && day === highlightedDay;
                  return (
                    <td 
                      key={day} 
                      className={isHighlighted ? 'highlighted-boss' : ''}
                    >
                      {days[day].join(" & ")}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BossTimer;
