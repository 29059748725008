import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import UserPanel from './UserPanel';
import BossTimer from './BossTimer'; // Import the BossTimer component
import PrivacyPolicy from './PrivacyPolicy';
import ToS from './ToS';


let [gUsername, gPassword] = '';

const RegistrationForm = ({ onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  

  const handleRegistration = async () => {
    try {
      setIsSubmitting(true); // Disable the button

      // Check if passwords match
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }

      // Check if the username already exists
      const userExistsResponse = await axios.post('https://api.celestialdesert.com/checkUserExists', { username });
      if (userExistsResponse.data.exists) {
        setError('Username already taken');
        return;
      }

      // Check if the email already exists
      const emailExistsResponse = await axios.post('https://api.celestialdesert.com/checkEmailExists', { email });
      if (emailExistsResponse.data.exists) {
        setError('Email already taken');
        return;
      }

      // Check if the username is lowercase
      if (username !== username.toLowerCase()) {
        setError('Username must be lowercase');
        return;
      }

      // Validate email format
      if (!validateEmail(email)) {
        setError('Invalid email format');
        return;
      }

      // Proceed with registration
      const registrationResponse = await axios.post('https://api.celestialdesert.com/register', {
        username,
        password,
        email,
      });

      console.log('Server answer:', registrationResponse.data);
      setRegistrationSuccess(true);
      setTimeout(() => {
        setRegistrationSuccess(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error during registration:', error.response?.data);
      setError('An error occurred during registration');
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="registration-modal">
      <h2>Registration</h2>
      {registrationSuccess ? (
        <div className="registration-success">
          <strong>Thanks for registering, you can now join the server!</strong>
        </div>
      ) : (
        <form>
          <label>
            Username (10 max characters):
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value.toLowerCase())}
			  maxLength={10}
            />
          </label>
          <label>
            Password (18 max characters, only letters and numbers):
            <input
              type="password"
              value={password}
              onChange={(e) => {
                const val = e.target.value;
                if (/^[a-zA-Z0-9]*$/.test(val)) {
                  setPassword(val);
                }
              }}
			  maxLength={18}
            />
          </label>
          <label>
            Confirm Password:
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                const val = e.target.value;
                if (/^[a-zA-Z0-9]*$/.test(val)) {
                  setConfirmPassword(val);
                }
              }}
            />
          </label>
          <label>
            Email:
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </label>
          <button
            type="button"
            onClick={handleRegistration}
            disabled={isSubmitting} // Disable the button if submitting
          >
            {isSubmitting ? 'Registering...' : 'Register'}
          </button>
          {error && <div className="registration-error">{error}</div>}
        </form>
      )}
    </div>
  );
};

const LoginForm = ({ onClose, onLoginSuccess }) => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loginSuccess, setLoginSuccess] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleLogin = async () => {
    try {
      const loginResponse = await axios.post('https://api.celestialdesert.com/checkLogin', {
        username: username,
        password: password,
      });

      console.log('Server answer:', loginResponse.data);

      if (!loginResponse.data.exists) {
        setError('Wrong username or password');
      } else {
        onLoginSuccess(loginResponse.data.userData);
        setLoginSuccess(true);
        gUsername = username;
        gPassword = password;
        console.log({ gUsername });
      }
    } catch (error) {
      console.error('Error during login:', error.response && error.response.data);
      setError('Error during login');
    }
  };

  return (
    <div className="login-modal">
      <h2>Login</h2>
      {loginSuccess ? (
        <div className="login-success">
          <strong>Logged in!</strong>
        </div>
      ) : (
        <form>
          <label>
            Username:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value.toLowerCase())}
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => {
                const val = e.target.value;
                if (/^[a-zA-Z0-9]*$/.test(val)) {
                  setPassword(val);
                }
              }}
            />
          </label>
          <button type="button" onClick={handleLogin}>Login</button>
          {error && <div className="login-error">{error}</div>}
        </form>
      )}
    </div>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRegistrationModalOpen: false,
      isLoginModalOpen: false,
      loginSuccess: false,
      userData: {},
    };
  }

  componentDidMount() {
    document.title = "Celestial Desert - Private Black Desert Server";
  }

  openRegistrationModal = () => {
    this.setState({ isRegistrationModalOpen: true });
  };

  closeRegistrationModal = () => {
    this.setState({ isRegistrationModalOpen: false });
  };

  openLoginModal = () => {
    this.setState({ isLoginModalOpen: true });
  };

  closeLoginModal = () => {
    this.setState({ isLoginModalOpen: false });
  };

  handleLoginSuccess = (userData) => {
    this.setState({ loginSuccess: true, userData, isLoginModalOpen: false });
    this.closeLoginModal();
  };

  handleLogout = () => {
    gUsername = "";
    gPassword = "";
    this.setState({ loginSuccess: false });
  };

  render() {
    const { isRegistrationModalOpen, isLoginModalOpen, loginSuccess, userData } = this.state;

    return (
      <Router>
        <div className="App">
          {isRegistrationModalOpen && <div className="overlay" onClick={this.closeRegistrationModal}></div>}
          {isLoginModalOpen && <div className="overlay" onClick={this.closeLoginModal}></div>}
          <img src="https://i.imgur.com/5QMtpa9.png" alt="Logo" className="logo" />

          <Routes>
            <Route path="/" element={
              !loginSuccess ? (
                <>
                  <div className="button-container">
                    <div className="button-row">
                      <button className="btn" onClick={() => window.location.href = "https://wiki.celestialdesert.com"}>Wiki</button>
                      <button className="btn" onClick={() => window.location.href = "https://discord.gg/Dkbx6AVbcV"}>Discord</button>
                      <button className="btn" onClick={() => window.location.href = "https://drive.google.com/file/d/18xjGttv1s-urqTvgeHPrXW-B47tP6iIs/view?usp=sharing"}>Download</button>
                      <button className="btn" onClick={() => window.location.href = "/bosstimer"}>Boss Timer</button>
                    </div>
                  </div>
                  <div className="video-container">
                    <iframe width="1280" height="720" src="https://www.youtube.com/embed/7mWxTPZ_F-M?si=ZYeGbn3nnFTPJPZx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                  <button className="login-button" onClick={this.openLoginModal}>Login</button>
                  <button className="register-button" onClick={this.openRegistrationModal}>Register</button>
                  {isLoginModalOpen && <LoginForm onClose={this.closeLoginModal} onLoginSuccess={this.handleLoginSuccess} />}
                  {isRegistrationModalOpen && <RegistrationForm onClose={this.closeRegistrationModal} />}
                </>
              ) : (
                <>
                  <UserPanel username={gUsername} password={gPassword} />
                  <button className="register-button" onClick={this.handleLogout}>Logout</button>
                </>
              )
            } />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/tos" element={<ToS />} />
            <Route path="/bosstimer" element={<BossTimer />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
		  <button className="privacy-policy-button" onClick={() => {
			window.location.href = "/privacy-policy";
		  }}>
			Privacy Policy
		  </button>
		  <button className="tos-button" onClick={() => {
			window.location.href = "/tos";
		  }}>
			Terms of Service
		  </button>

          {/* Conditionally render the Login and Registration modals */}
          {isLoginModalOpen && <LoginForm onClose={this.closeLoginModal} onLoginSuccess={this.handleLoginSuccess} />}
          {isRegistrationModalOpen && <RegistrationForm onClose={this.closeRegistrationModal} />}
        </div>
      </Router>
    );
  }
}

export default App;
