import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const UserPanel = ({ username, password, toggleBossTimer }) => {
  const [userData, setUserData] = useState(null);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponResponse, setCouponResponse] = useState('');
  const [isResponseSuccess, setIsResponseSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [canVote, setCanVote] = useState(false);
  const [isCouponSubmitting, setIsCouponSubmitting] = useState(false); // New state for coupon submission loading

  useEffect(() => {
    const fetchData = async () => {
      try {
        const emailResponse = await axios.post('https://api.celestialdesert.com/getEmail', { username, password });
        const creditsResponse = await axios.post('https://api.celestialdesert.com/getCredits', { username, password });
        
        setUserData({
          email: emailResponse.data.email,
          credits: creditsResponse.data._count,
          userNickname: creditsResponse.data._userNickname,
        });

        setIsLoading(false);
      } catch (error) {
        console.error('Error during server request:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [username, password]);

  useEffect(() => {
    if (userData && userData.userNickname) {
      axios.post('https://api.celestialdesert.com/setFamilyName', {
        username, password, familyname: userData.userNickname,
      })
      .catch(error => {
        console.error('Error updating family name:', error);
      });
    }
  }, [userData, username, password]);

  useEffect(() => {
    const checkVotingStatus = async () => {
      try {
        const response = await axios.post('https://api.celestialdesert.com/can-vote', { username });
        setCanVote(response.data.canVote);
      } catch (error) {
        console.error('Error checking voting status:', error);
      }
    };

    if (userData) {
      checkVotingStatus();
    }
  }, [userData, username]);


  const handleOpenCouponModal = () => {
    if (userData.userNickname === "Not chosen yet!") {
      alert("You do not have a family name yet, you can't use the coupon system. Please pick your name in-game first.");
    } else {
      setIsCouponModalOpen(true);
    }
  };

  const handleCloseCouponModal = () => setIsCouponModalOpen(false);

  const handleCouponSubmit = async () => {
    setIsCouponSubmitting(true); // Set loading state to true
    try {
      const response = await axios.post('https://api.celestialdesert.com/sendCoupon', {
        username: username,
        coupon: couponCode
      });
      if (response.status === 200) {
        setCouponResponse(response.data.message);
        setIsResponseSuccess(true);
      } else {
        setCouponResponse('Failed to apply coupon');
        setIsResponseSuccess(false);
      }
    } catch (error) {
      console.error('Error during POST request:', error);
      setCouponResponse('Error submitting coupon');
      setIsResponseSuccess(false);
    } finally {
      setIsCouponSubmitting(false); // Reset loading state
    }
  };

  const renderCouponModal = () => (
    isCouponModalOpen && (
      <div className="overlay">
        <div className="login-modal">
          <span className="close" onClick={handleCloseCouponModal}>&times;</span>
          <h2>Enter Coupon Code</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <input 
              type="text"
              placeholder="Coupon code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              autoFocus 
              disabled={isCouponSubmitting} // Disable input while submitting
            />
            <button 
			  type="button" 
			  onClick={handleCouponSubmit} 
			  disabled={isCouponSubmitting} // This ensures the button is disabled
			  className={isCouponSubmitting ? 'btn-disabled' : ''} // Optional: Add a class for styling when disabled
			>
			  {isCouponSubmitting ? 'Activating code...' : 'Submit'}
			</button>
            <div style={{ color: isResponseSuccess ? 'green' : 'red', marginTop: '10px' }}>
              {couponResponse}
            </div>
          </form>
        </div>
      </div>
    )
  );

  const renderUserPanel = () => {
    if (isLoading) {
      return (
		  <div
			style={{
			  fontSize: '24px',
			  color: '#000', // Set the text color to black
			  backgroundColor: '#968be1', // Background color of the rectangle
			  padding: '20px', // Add some padding for spacing inside the rectangle
			  border: '2px solid #000', // Black border
			  borderRadius: '5px', // Optional: add a slight border radius to the rectangle
			  textAlign: 'center', // Center-align the text
			}}
		  >
			Retrieving your data...
		  </div>
		);

    }
    if (userData) {
      let voteMessage;
      if (userData.userNickname === "Not chosen yet!") {
        voteMessage = "No, you didn't pick your family name yet";
      } else {
        voteMessage = canVote ? "Yes" : "No";
      }

      return (
        <div className="user-panel">
          <div className="your-prof"><h2>Your Profile</h2></div>
          <p>Username: {username}</p>
          <p>Email: {userData.email}</p>
          <p>Credits: {userData.credits}</p>
          <p>Family Name: {userData.userNickname}</p>
          <p>Vote Available: {voteMessage}</p>
          <div className="button-row">
            <button className="couponbtn" onClick={handleOpenCouponModal}>Coupon</button>
            <button 
              className="couponbtn" 
              onClick={() => window.location.href = `https://www.arena-top100.com/index.php?a=in&u=celestialdesert&id=${userData.userNickname}`} 
              disabled={!canVote || userData.userNickname === "Not chosen yet!"}
            >
              Vote
            </button>
			<button className="couponbtn" onClick={() => window.location.href = "https://www.paypal.com/donate/?hosted_button_id=X3XJ53NWQQJUQ"}>Donate</button>
          </div>
        </div>
      );
    } else {
      return <div>Failed to load user data</div>;
    }
  };

  const renderButtonRow = () => (
    <div className="button-container">
      <div className="button-row">
        <button className="btn" onClick={() => window.location.href = "https://wiki.celestialdesert.com"}>Wiki</button>
        <button className="btn" onClick={() => window.location.href = "https://discord.gg/Dkbx6AVbcV"}>Discord</button>
        <button className="btn" onClick={() => window.location.href = "https://drive.google.com/file/d/18xjGttv1s-urqTvgeHPrXW-B47tP6iIs/view?usp=sharing"}>Download</button>
        <button className="btn" onClick={() => window.location.href = "/bosstimer"}>Boss Timer</button> {/* Link to BossTimer page */}
      </div>
    </div>
  );

  return (
    <>
      {renderUserPanel()}
      {renderButtonRow()}
      {renderCouponModal()}
    </>
  );
};

export default UserPanel;
